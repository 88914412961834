<template>
   <div ref="stripe_form" id="stripe-form">
   </div>
    </template>
    
    <script>
    import {initialize} from './Stripe/Checkout.js';
   export default{
    name:'Buy',
    data(){
        return{
          checkoutElement:null
        }
    },
    created(){
        this.buildForm().then(result=>this.checkoutElement=result);
    },
    beforeDestroy(){
      this.checkoutElement.destroy();
    },
    methods:{
        async buildForm() {
      const { data } = await this.$axios.get(
        `/public/course/details/${this.$route.params.slug}`
      );
      this.purchaseData = {
        product_id:data.id,
        user_email:localStorage.getItem('email_user'),
      };
      return initialize(this.purchaseData, this.$axios);
      
    },
    }
   }
    </script>
    