
const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHEABLE_KEY);


// Create a Checkout Session as soon as the page loads
export async function initialize(courseData,axios) {
  
  const {data:{clientSecret}} = await axios.post(
    "/public/checkout/sessionInit",
    JSON.stringify(courseData)
  );


  const checkout = await stripe.initEmbeddedCheckout({
    clientSecret:clientSecret,
  });

  // Mount Checkout
  checkout.mount('#stripe-form');
  return checkout;
}